import React, { Component, Suspense, lazy } from 'react'
import { Switch, Route } from 'react-router-dom'

const Home = lazy(() => import("./dashboard/Home"))
const Resources = lazy(() => import("./dashboard/Resources"))
const URLParser = lazy(() => import('./actions/URLParser'))
const TextParser = lazy(() => import('./actions/TextParser'))
const TwitterParser = lazy(() => import('./actions/TwitterParser'))
const Signin = lazy(() => import("./auth/Signin"))
const Signup = lazy(() => import("./auth/Signup"))

export class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback='/'>
        <Switch>
          <Route exact path="/" component={ Home } />
          <Route exact path="/signin" component={ Signin } />
          <Route exact path="/signup" component = { Signup } /> 
          <Route exact path="/resources" component={ Resources } />
          <Route exact path="/dashboard/url" component={ URLParser } />
          <Route exact path="/dashboard/text" component={ TextParser} />
          <Route exact path="/dashboard/twitter" component={ TwitterParser} />
        </Switch>
      </Suspense>
    )
  }
}

export default AppRoutes
