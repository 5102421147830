import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Dropdown } from "react-bootstrap";


export class Header extends Component {
  closeMenu(e) {
    e.target.closest(".dropdown").classList.remove("show");
    e.target.closest(".dropdown .dropdown-menu").classList.remove("show");
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    document.querySelector("body").classList.toggle("az-header-menu-show");
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      document.querySelector("body").classList.remove("az-header-menu-show");
    }
  }

  render() {
    return (
      <div>
        <div className="az-header">
          <div className="container">
            <div className="az-header-left">
              <a href="/" className="az-logo">
                <span> IOCParser</span>
              </a>
              <a
                id="azMenuShow"
                onClick={event => this.toggleHeaderMenu(event)}
                className="az-header-menu-icon d-lg-none"
                href="https://iocparser.com"
              >
                <span></span>
              </a>
            </div>
            <div className="az-header-menu">
              <div className="az-header-menu-header">
                <Link to="/" className="az-logo">
                  <span></span> IOC Parser
                </Link>
                <a
                  href="https://iocparser.com"
                  onClick={event => this.toggleHeaderMenu(event)}
                  className="close"
                >
                  &times;
                </a>
              </div>
              <ul className="nav">
                <li
                  className={
                    (window.location.pathname === "/")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link to="/" className="nav-link">
                    <i className="typcn typcn-home-outline"></i> Home
                  </Link>
                </li>
               

                <li className="nav-item">
                  <Dropdown
                    className={
                      this.isPathActive("/dashboard/url") ||
                      this.isPathActive("/dashboard/text") ||
                      this.isPathActive("/dashboard/twitter") ||
                      this.isPathActive("/integrations")
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <Dropdown.Toggle as={"a"} className="nav-link with-sub">
                      <i className="typcn typcn-th-large-outline"></i> Dashboard
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="az-menu-sub az-menu-sub-mega">
                      <div className="container">
                        <div>
                          <nav className="nav">
                            <span>Actions</span>
                            <Link
                              to="/dashboard/url"
                              className={
                                this.isPathActive("/dashboard/url")
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                            >
                              <h6 className="tx-secondary">Parse URL</h6>
                              
                            </Link>
                            <Link
                              to="/dashboard/text"
                              className={
                                this.isPathActive("/dashboard/text")
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                            >
                              <h6 className="tx-secondary">Parse Text</h6>
                              
                            </Link>
                            <Link
                              to="/dashboard/twitter"
                              className={
                                this.isPathActive("/dashboard/twitter")
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                            >
                              <h6 className="tx-secondary">Parse Twitter</h6>
                              
                            </Link>
                          
                          </nav>
                        </div>
                        <div>
                          <nav className="nav">
                            <span>Documentation</span>
                            <a className="nav-link" href="https://docs.iocparser.com/" rel="noopener noreferrer" target="_blank"> <h6 className="tx-secondary"> API Documentation</h6></a>
                            <a className="nav-link" href="https://docs.iocparser.com/" rel="noopener noreferrer" target="_blank"> <h6 className="tx-secondary"> Use Cases</h6></a>
                          
                           
                          </nav>
                        </div>

                        <div>
                          <nav className="nav">
                            <span>Extras</span>
                            <a className="nav-link" href="https://status.iocparser.com/" rel="noopener noreferrer" target="_blank"> <h6 className="tx-secondary"> Status Page</h6></a>
                            <a className="nav-link" href="https://twitter.com/IOCParser" rel="noopener noreferrer" target="_blank"> <h6 className="tx-secondary"> Contact Me</h6></a>
                            <a className="nav-link" href="https://www.buymeacoffee.com/iocparser" rel="noopener noreferrer" target="_blank"> <h6 className="tx-secondary"> Buy Me a Coffee</h6></a>
                          
                           
                          </nav>
                        </div>
                       
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>

                <li
                  className={
                    (window.location.pathname === "/resources")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link to="/resources" className="nav-link">
                    <i className="typcn typcn-book"></i> Resources
                  </Link>
                </li>

                {/* <li className="nav-item">
                  <Dropdown
                    className={
                      this.isPathActive("/extra")
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <Dropdown.Toggle as={"a"} className="nav-link with-sub">
                      <i className="typcn typcn-tabs-outline"></i>Extras
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="az-menu-sub">
                     
                      <a className="nav-link" href="https://monitoring.iocparser.com/" rel="noopener noreferrer" target="_blank"><i className="fas fa-desktop mr-1 mt-2 mb-2"></i> Status Page</a>
                      <a className="nav-link" href="https://twitter.com/IOCParser" rel="noopener noreferrer" target="_blank"><i className="fab fa-twitter mr-1 mt-2 mb-2"></i> Contact Me</a>
                      <a className="nav-link" href="https://www.buymeacoffee.com/iocparser" rel="noopener noreferrer" target="_blank"><i className="fas fa-coffee mr-1 mt-2 mb-2"></i> Buy Me a Coffee</a>
                 
                    </Dropdown.Menu>
                  </Dropdown>
                </li> */}
              </ul>
            </div>


            
            <div className="az-header-right">

              
 
              <Dropdown className="az-header-notification">
                <Dropdown.Toggle as={"a"} className="new">
                  <i className="typcn typcn-bell"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="az-menu-sub">
                  
                  <h5 className="card-title"><i className="typcn typcn-heart"/> Version: {process.env.REACT_APP_VERSION}</h5>
                  <p className="font-weight-normal text-muted">A free, fast, and reliable service that enables you to extract Indicator of Compromise (IOCs) and intelligence from different data sources.</p>
                   
             
                 
                 
                 
                </Dropdown.Menu>
              </Dropdown>

              

              

              

              <Dropdown className="az-header-notification-user">
                <Dropdown.Toggle as={"a"} className="new">
                  <i className="typcn typcn-user-outline"></i>
                </Dropdown.Toggle>
              
                 

                  <Dropdown.Menu className="az-menu-sub">
                    <Link
                        to="/comingsoon"
                        className={
                          this.isPathActive("/comingsoon")
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        <h5 className="card-title"><i className="typcn typcn-user-add"/> Sign In</h5>
                        <p className="font-weight-normal"><b>Coming Soon</b> - Creating an account will enable you to store historical data, view metrics and much more.</p>
                
                    </Link>
                  </Dropdown.Menu>
              
                  
                 
          
              </Dropdown>

             
            </div>
          </div>
        </div>
      </div>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}

export default withRouter(Header);
