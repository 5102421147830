import React, { Component } from 'react'

export class Footer extends Component {
  render() {
    return (
      <div>
        <div className="az-footer ht-40">
          <div className="container ht-100p pd-t-0-f">
            <div className="d-sm-flex justify-content-center justify-content-sm-between py-2 w-100">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block"><b><a href="https://twitter.com/IOCParser" target="_blank" rel="noopener noreferrer">@IOCParser </a></b></span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"><b><a href="https://iocparser.com" target="_blank" rel="noopener noreferrer">IOC Parser - Free IOC Extracting Service</a></b> </span>
            </div>
          </div>{/* container */}
        </div>{/* az-footer */}
      </div>
    )
  }
}

export default Footer
